"use client";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import MUISnackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import useSnackbar from "./useSnackbar";
import { snackbarMessage } from "./utils";
function ActionButton({
  action
}: {
  action: {
    label: string;
    action: (...args: any) => void;
  };
}) {
  return <Button variant="important" onClick={action.action} data-sentry-element="Button" data-sentry-component="ActionButton" data-sentry-source-file="Snackbar.tsx">
            {action.label}
        </Button>;
}
export default function Snackbar() {
  const snackbar = useSnackbar();
  const handleClose = (_event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    snackbarMessage(null);
  };
  return <MUISnackbar anchorOrigin={{
    vertical: "bottom",
    horizontal: "center"
  }} open={snackbar.data?.open} autoHideDuration={snackbar.data?.action ? undefined : 6000} onClose={handleClose} data-sentry-element="MUISnackbar" data-sentry-component="Snackbar" data-sentry-source-file="Snackbar.tsx">
            {snackbar.data?.action ? <SnackbarContent message={snackbar.data?.message} action={<ActionButton action={snackbar.data?.action} />} /> : <Alert onClose={handleClose} severity={snackbar.data?.color} variant="filled" sx={{
      width: "100%",
      display: "flex",
      alignItems: "center"
    }}>
                    {snackbar.data?.message}
                </Alert>}
        </MUISnackbar>;
}