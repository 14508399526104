import { DeployRefreshManager } from "components/DeployRefreshManager/DeployRefreshManager";
import Snackbar from "components/Snackbar/Snackbar";
import React from "react";
import { AppRouterCacheProvider } from "@mui/material-nextjs/v15-appRouter";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
export default function AppThemeProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <AppRouterCacheProvider data-sentry-element="AppRouterCacheProvider" data-sentry-component="AppThemeProvider" data-sentry-source-file="AppThemeProvider.tsx">
            <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="AppThemeProvider.tsx">
                {children}
                <Snackbar data-sentry-element="Snackbar" data-sentry-source-file="AppThemeProvider.tsx" />
                <DeployRefreshManager data-sentry-element="DeployRefreshManager" data-sentry-source-file="AppThemeProvider.tsx" />
            </ThemeProvider>
        </AppRouterCacheProvider>;
}