/* eslint-disable i18next/no-literal-string */
"use client";

import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
import { useEffect } from "react";
import { t } from "locales";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { HeaderSimple } from "components/Layout/Header/Header";
import { Figtree } from "next/font/google";
import "/styles/main.css";
import AppThemeProvider from "components/Layout/AppThemeProvider";
const figtree = Figtree({
  subsets: ["latin"],
  weight: "400"
});
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html lang={process.env.NEXT_PUBLIC_LOCALE} data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
            <head>
                <title>{t.layouts.error} - {t["site-name"]}</title>
                <meta name="robots" content="noindex" data-sentry-element="meta" data-sentry-source-file="global-error.tsx" />
            </head>
            <body className={figtree.className}>
                <div id="layout-root">
                    <AppThemeProvider data-sentry-element="AppThemeProvider" data-sentry-source-file="global-error.tsx">
                        <HeaderSimple data-sentry-element="HeaderSimple" data-sentry-source-file="global-error.tsx" />
                        <StyledGridContainer container alignItems="center" direction="column" justifyContent="center" spacing={4} data-sentry-element="StyledGridContainer" data-sentry-source-file="global-error.tsx">
                            <Grid item data-sentry-element="Grid" data-sentry-source-file="global-error.tsx">
                                <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="global-error.tsx">{t.layouts.error}</Typography>
                            </Grid>
                            <Grid item data-sentry-element="Grid" data-sentry-source-file="global-error.tsx">
                                <Link href="/" underline="always" variant="h4" data-sentry-element="Link" data-sentry-source-file="global-error.tsx">
                                    {t["go-to-homepage"]}
                                </Link>
                            </Grid>
                        </StyledGridContainer>
                    </AppThemeProvider>
                </div>
            </body>
        </html>;
}
const StyledGridContainer = styled(Grid)(() => ({
  margin: "0 auto",
  maxWidth: "80vw",
  minHeight: "60vh"
}));