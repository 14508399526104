import { useState } from "react";
import { ISnackbarMessage } from "./interface";
import { useEffect } from "react";
import { emitter } from "./utils";
export default function useSnackbar() {
  const [data, setData] = useState<ISnackbarMessage | null>(null);
  const snackbarEvent = (event: ISnackbarMessage | null) => {
    if (!event?.open) setData(prevState => {
      if (prevState) return {
        ...prevState,
        open: false,
        message: "",
        action: undefined
      };else return null;
    });else setData(event);
  };
  useEffect(() => {
    emitter.on("snackbar", snackbarEvent);
    return () => {
      emitter.off("snackbar", snackbarEvent);
    };
  }, []);
  return {
    data
  };
}