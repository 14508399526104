"use client";

import { snackbarMessage } from "components/Snackbar/utils";
import { JSX, useEffect, useRef } from "react";

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
function useInterval<P extends Function>(callback: P, {
  interval
}: {
  interval: number;
}): void {
  const savedCallback = useRef<P>(null);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = (): void => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };
    if (interval !== null) {
      const id = setInterval(tick, interval);
      return () => clearInterval(id);
    }
  }, [interval]);
}
export function DeployRefreshManager(): JSX.Element {
  // @ts-expect-error
  const clientLibBuildId = __BUILD_ID__;
  useInterval(async () => {
    const build = await fetch("/api/build-id").then(response => {
      if (!response.ok) {
        return null;
      }
      return response.json();
    }).catch(_ => {});
    if (!build) return;
    if (build.buildId && clientLibBuildId && build.buildId !== clientLibBuildId) {
      snackbarMessage({
        color: "info",
        message: "A new version of the app is available",
        action: {
          action: () => {
            // @ts-expect-error
            window.location.reload(true);
          },
          label: "Refresh"
        },
        open: true
      });
      // There's a new version deployed that we need to load
    }
  }, {
    interval: 3600000
  });
  return <> </>;
}